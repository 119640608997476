<template>
  <vx-card title="Advance Payment">
    <vs-tabs>
      <vs-tab label="Waiting Payment">
        <waiting-payment></waiting-payment>
      </vs-tab>
      <vs-tab label="Payment">
        <div class="tab-text">
          <payment></payment>
        </div>
      </vs-tab>
      <vs-tab label="Applied">
        <div class="tab-text">
          <applied></applied>
        </div>
      </vs-tab>
    </vs-tabs>
  </vx-card>
</template>
<script>
import WaitingPayment from "./waiting_payment";
import Payment from "./payment";
import Applied from "./applied";

export default {
  components: {
    WaitingPayment,
    Payment,
    Applied
  },
};
</script>