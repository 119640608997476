<template>
  <div class="vx-col w-5/6 ml-auto mr-auto">
    <span>
      <h4 class="mb-5">Supplier Invoice</h4>
    </span>
    <div class="vx-row mb-2">
      <div class="vx-col w-1/2">
        <vs-input
          class="w-full"
          label="Supplier"
          name="Supplier"
          v-model="selected.supplier_name"
          readonly
        />
      </div>
      <div class="vx-col w-1/2">
        <vs-input
          class="w-full"
          label="PO Code"
          name="PO Code"
          v-model="selected.po_code"
          readonly
        />
      </div>
    </div>
    <div class="vx-row mb-2">
      <div class="vx-col w-1/2">
        <vs-input
          class="w-full"
          label="Payment Term"
          name="Payment Term"
          v-model="selected.payment_term"
          readonly
        />
      </div>
      <div class="vx-col w-1/2">
        <vs-input
          class="w-full"
          label="PO Date"
          name="PO Date"
          :value="dateFormat(selected.po_date)"
          readonly
        />
      </div>
    </div>
    <div class="vx-row mb-2" style="z-index: 99999 !important">
      <div class="vx-col w-full">
        <label class="vs-input--label">Bank Name</label>
        <multiselect
          class="selectExample"
          v-model="create.supplierAccount"
          :options="optionSupplierAccountName"
          :multiple="false"
          :allow-empty="false"
          :group-select="false"
          :max-height="160"
          :limit="4"
          placeholder=" Type to search"
          track-by="ID"
          label="Name"
          :disabled="false"
        >
          <template slot="singleLabel" slot-scope="dt">
            <span class="option__desc">
              <span class="option__title">
                {{ dt.option.BankName }} - {{ dt.option.AccountNumber }}
                {{ dt.option.AccountName }}
              </span>
            </span>
          </template>

          <template slot="option" slot-scope="dt">
            <div class="option__desc">
              <span class="option__title">
                {{ dt.option.BankName }} - {{ dt.option.AccountNumber }}
                {{ dt.option.AccountName }}
              </span>
            </div>
          </template>
        </multiselect>
      </div>
    </div>
    <div class="vx-row mb-2">
      <div class="vx-col w-full">
        <vs-input
          class="w-full"
          label="Total Paid"
          name="Total Paid"
          v-model="create.nominal"
          readonly
        />
      </div>
    </div>
     <div class="vx-row mb-2 ml-1">
      <h5>Line Item</h5>
    </div>
    <vs-table stripe border description :sst="true" :data="table.data">
      <template slot="thead">
        <vs-th>Item</vs-th>
        <vs-th>Qty Inv</vs-th>
        <vs-th>Net</vs-th>
        <vs-th>Tax</vs-th>
      </template>

      <template slot-scope="{ data }">
        <vs-tr
          :data="tr"
          :key="indextr"
          v-for="(tr, indextr) in data"
          :class="data[indextr].class"
        >
          <vs-td :data="data[indextr].code">
            <div class="vx-row ml-0">
              <span class="w-5/5">{{ data[indextr].item_name }}</span>
            </div>
          </vs-td>
          <vs-td>
            <div class="vx-row ml-0">
              <span class="w-5/5">{{ data[indextr].quantity }} ({{data[indextr].item_unit}}) </span>
            </div>
          </vs-td>
          <vs-td>
            <div class="vx-row ml-0">
              <span class="w-5/5">{{ data[indextr].net }}</span>
            </div>
          </vs-td>
          <vs-td>
            <div class="vx-row ml-0">
              <span class="w-5/5">{{ data[indextr].tax }}</span>
            </div>
          </vs-td>
        </vs-tr>
      </template>
    </vs-table>
    <div class="vx-row margin-btm" style="width: 100%; margin-left: 0%">
      <vs-button @click="handleSubmit()" icon-pack="feather" icon="icon-save">
        Submit
      </vs-button>
    </div>
  </div>
</template>
<script>
import Datepicker from "vuejs-datepicker";
import moment from "moment";
export default {
  components: {
    Datepicker,
  },
  props: {
    selected: {
      type: Object,
    },
  },
  created() {},
  data() {
    return this.initialState();
  },
  methods: {
    initialState() {
      return {
        optionSupplierAccountName: [],
        vendor:'',
        poCode:'',
        table:{
          data:[],
        },
        create: {
          supplierAccount: {},
          nominal: 0,
        },
      };
    },
    dateFormat(date) {
      if (date) {
        return moment(String(date)).format("YYYY-MM-DD");
      }
    },
    handleSubmit() {
      console.log("handle");
      this.$vs.loading();
      // console.log(this.create.supplierAccount)
      this.$http
        .post("/api/v1/pi/advance-purchase-payment", {
          pi_id: this.selected.id,
          supplier_account: this.create.supplierAccount.ID,
          total_paid: this.create.nominal.toString().replace(/\d/gi,'')
        })
        .then((resp) => {
          console.log(resp);
          this.$nextTick(() => {
            // this.$emit("closeDetail");
            this.$emit("closeDetail", true);
            this.$vs.loading.close();
          });
        });
    },
    getData() {
      this.$vs.loading();
      this.$http
        .get("/api/v1/pi/vendor-invoice-line/" + this.selected.id)
        .then((resp) => {
          this.$vs.loading.close();
          if (resp.code == 200) {
            // this.table.inv_code = resp.data.inv_code;
            
            let _this = this;
            resp.data.data_line.forEach(function (element, index) {
              _this.table.data.push({
                net: element.net
                  .toString()
                  .replace(/,/g, "")
                  .replace(/\B(?=(\d{3})+(?!\d))/g, ","),
                tax: element.tax
                  .toString()
                  .replace(/,/g, "")
                  .replace(/\B(?=(\d{3})+(?!\d))/g, ","),
                quantity: element.quantity
                  .toString()
                  .replace(/,/g, "")
                  .replace(/\B(?=(\d{3})+(?!\d))/g, ","),
                item_name: element.item_name,
                item_unit: element.item_unit,
                id: element.id,
                amount_uom: element.amount_uom,
                qty_received: element.qty_received,
                tax_rate: element.tax_rate,
              });
            });

          } else {
            this.$vs.notify({
              color: "danger",
              title: "Error",
              text: resp.message,
              position: "top-right",
              iconPack: "feather",
              icon: "icon-x-circle",
            });
          }
        })
        .catch((error) => {
          this.$vs.loading.close();
          this.$vs.notify({
              color: "danger",
              title: "Error",
              text: "-",
              position: "top-right",
              iconPack: "feather",
              icon: "icon-x-circle",
            });
          console.log(error);
        });
    },
    getOptionAccountBank() {
      this.$vs.loading();
      this.$http
        .get("/api/v1/pi/supplier-account/" + this.selected.supplier_id, {
          params: {
            order: "name",
            sort: "asc",
          },
        })
        .then((resp) => {
          console.log(resp);
          this.optionSupplierAccountName = resp.data.account;
          resp.data.account.map((v) => {
            if (v.IsDefault == 1) {
              this.create.supplierAccount = v
            }
          })
          this.create.nominal = this.selected.total_invoice
            .toString()
            .replace(/\B(?=(\d{3})+(?!\d))/g, ".");
          this.$vs.loading.close();
        });
    },
  },
  mounted() {
    // if (this.piId) {
    // this.getData();
    this.getOptionAccountBank();
    this.getData();
    // this.table.inv_code = this.invCode != 0 ? this.invCode : null;
    // }
  },
  computed: {},
  watch: {
    selected() {
      Object.assign(this.$data, this.initialState());
      // this.getData();
      this.getOptionAccountBank();
      // this.table.inv_code = this.invCode != 0 ? this.invCode : null;
    },
  },
};
</script>
<style scoped>
.pagination {
  display: flex;
  margin: 0.25rem 0.25rem 0;
}

.pagination button {
  flex-grow: 1;
}

.pagination button:hover {
  cursor: pointer;
}

.horizontal-divider {
  border-top: 1px solid #c1bbff;
  padding: 5px;
  margin-top: 15px;
}
</style>