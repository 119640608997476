<template>
  <div>
    <div class="vx-row mb-12">
      <div
        v-bind:class="[
          detail ? detailShow + ' vertical-divider' : '',
          detailHide,
        ]"
      >
        <!-- 
       ADDONs
         
        -->
        <data-table
          :responseData="responseData"
          :propsParams="params"
          :header="header"
          @reloadDataFromChild="reloadData"
        >
          <template slot="tbody">
            <vs-tr
              :key="indextr"
              v-for="(tr, indextr) in responseData.records"
              v-bind:class="[selectedData.id == tr.id ? 'colored' : '', '']"
              style="
                 {
                  border: 1px solid;
                }
              "
            >
              <vs-td>
                <template>
                  <vx-tooltip text="Apply To Invoice">
                    <vs-button
                      color="blue"
                      type="line"
                      @click="payment(tr)"
                      icon-pack="feather"
                      icon="icon-check"
                      :disabled="tr.invoice_pembanding==''"
                    />
                  </vx-tooltip>
                </template>
              </vs-td>
              <vs-td>
                {{ tr.inv_code }}
              </vs-td>
              <vs-td>
                {{ tr.supplier_name }}
              </vs-td>
              <vs-td>
                {{ priceFormat(tr.total_invoice) }}
              </vs-td>
              <vs-td>
                Code :  {{ (tr.invoice_pembanding==''?'Belum Ada Invoice':tr.invoice_pembanding) }} <br>
                Total Tagihan : {{ tr.invoice_pembanding==""?"Belum Ada Invoice":tr.total_invoice_pembanding }} <br>
              </vs-td>
            </vs-tr>
          </template>
        </data-table>
      </div>
      <!-- detail-->
      <transition name="detail-fade">
        <div
          v-if="detail"
          v-bind:class="[
            detail ? ' nonfixed vx-col md:w-1/2 w-full mb-base' : '',
            detailHide,
          ]"
        >
          <div>
            <vs-col
              style="padding-bottom: 8px"
              vs-offset="8"
              vs-type="flex"
              vs-justify="rigth"
              vs-align="rigth"
              vs-w="4"
            >
              <vs-button
                size="small"
                v-on:click="closeDetail"
                color="grey"
                icon-pack="feather"
                icon="icon-x-square"
                >Close</vs-button
              >
            </vs-col>

            <form-payment
              :selected="selectedData"
              @closeDetail="closeDetail"
            ></form-payment>
          </div>
        </div>
      </transition>
    </div>
  </div>
</template>


<script>
import detail from "./detail.vue";
import moment from "moment";
export default {
  components: {
    "form-payment": detail,
  },
  props: {
    selected: Object,
    option: Object,
  },
  data() {
    return {
      params: {
        search: "",
        length: 10,
        page: 1,
        order: "asc",
        sort: "code",
      },
      header: [
        {
          text: "Action",
          sortable: false,
          width: "5%",
        },
        {
          text: "Advance Invoice",
          value: "code",
          // width: '5%'
        },
        {
          text: "Client",
          sortable: false,
        },
        {
          text: "Amount",
          sortable: false,
        },
        {
          text: "Invoice",
          sortable: false,
        },
      ],
      responseData: {},
      detailShow: "vx-col md:w-1/2 w-full mb-base",
      detailHide: "core vx-col md:w-1/1 w-full mb-base",
      detail: false,
      selectedData: {},
      promptCancel: {},
    };
  },
  computed: {},
  watch: {
    selectedData: function () {
      console.log(this.selectedData, "watch");
      // this.kitting.territory = this.selected.territory
      // this.reloadData(this.params);
    },
  },
  mounted() {
    console.log(this.detail);
    console.log(this.selectedData);
    // this.reloadData(this.params);

    // this.getData();
    // this.page(1);
    // this.dataId = this.data[0].id
  },
  methods: {
    dateFormat(value) {
      return moment(String(value)).format("DD/MM/YYYY");
    },
    payment(selected) {
      // window.scrollTo(0, 0);
      // this.reloadData(this.params);
      this.$vs.loading();
      // console.log(this.create.supplierAccount)
      this.$http
        .post("/api/v1/pi/advance-purchase-payment/apply", {
          pi_id: selected.id,
        })
        .then((resp) => {
          this.$nextTick(() => {
            if (resp.code != 200) {
              this.$vs.notify({
                color: "danger",
                title: "Failed",
                text: resp.message,
                position: "top-right",
                iconPack: "feather",
                icon: "icon-x-circle",
              });
            } else {
              this.$vs.notify({
                color: "success",
                title: "Success",
                text: resp.message,
                position: "top-right",
                iconPack: "feather",
                icon: "icon-x-circle",
              });
            }
            this.reloadData(this.params);
            // this.$emit("closeDetail");
            // this.$emit("closeDetail", true);
            this.$vs.loading.close();
          });
        });
    },

    closeDetail(params = false) {
      window.scrollTo(0, 0);
      this.detail = false;
      this.selectedData = {
        ID: 0,
      };
      if (params == true) {
        this.reloadData(this.params);
      }
    },
    reloadData(params) {
      this.params = params;
      console.log(params);
      this.$vs.loading();
      this.$http
        .get("api/v1/pi/advance-purchase-invoice", {
          params: {
            search: params.search,
            length: params.length,
            page: params.page,
            sort: params.order,
            order: params.sort,
            status: "11",
          },
        })
        .then((resp) => {
          if (resp.code == 500) {
            this.$vs.loading.close();
          } else if (resp.code == 200) {
            this.$vs.loading.close();
            this.data = resp.data.records;
            this.responseData = resp.data;
            this.responseData.recordsTotal = resp.data.record_total;
            this.responseData.length = resp.data.record_total_search;
          } else {
            this.$vs.loading.close();
          }
        });
      // this.$http
      // .get("/api/v1/master/price-rule", {
      //   params: {
      //     search: params.search,
      //     length: params.length,
      //     page: params.page,
      //     order: "",
      //     sort: "",
      //   }
      // })
    },
  },
};
</script>
 <style scoped>
.nonfixed {
  position: inherit;
  padding-left: 20px;
}
.core-enter-active {
  transition: all 0.3s ease;
}
.core-leave-active {
  transition: all 0.8s cubic-bezier(1, 0.5, 0.8, 1);
}
.core-enter, .core-leave-to
/* .slide-fade-leave-active below version 2.1.8 */ {
  transform: translateX(10px);
  opacity: 0;
}
.core {
  -webkit-transform-origin-y: all 1s ease;
  -webkit-transition: all 1s ease;
  -moz-transition: all 1s ease;
  -o-transition: all 1s ease;
  transition: all 1s ease;
}
.vs-con-table .vs-con-tbody .vs-table--tbody-table {
  font-size: 12px;
}
.vertical-divider {
  border-right: 1px solid #7367f0;
  /* min-height: 800px; */
  /* height: 100%; */
  -webkit-mask-position-y: fixed;
  /* padding: 5px; */
}
.vs-con-table.stripe .tr-values:nth-child(2n) {
  background: beige;
}
.colored {
  border: 1px solid #7367f0;
  position: fixed;
  left: 4%;
  top: 40%;
  max-width: 45%;
  z-index: 999999999999;
  background: antiquewhite;
  background-color: antiquewhite;
}
</style>