<template>
  <div class="vx-col w-5/6 ml-auto mr-auto">
    <span>
      <h4 class="mb-5">Vendor Invoice</h4>
    </span>

    <div class="vx-row mb-2" style="z-index: 99999 !important">
      <div class="vx-col w-full">
        <label class="vs-input--label">Bank Name</label>
        <multiselect
          class="selectExample"
          v-model="create.supplierAccount"
          :options="optionSupplierAccountName"
          :multiple="false"
          :allow-empty="false"
          :group-select="false"
          :max-height="160"
          :limit="4"
          placeholder=" Type to search"
          track-by="ID"
          label="Name"
          :disabled="false"
        >
          <template slot="singleLabel" slot-scope="dt">
            <span class="option__desc">
              <span class="option__title">
                {{ dt.option.BankName }} - {{ dt.option.AccountNumber }}
                {{ dt.option.AccountName }}
              </span>
            </span>
          </template>

          <template slot="option" slot-scope="dt">
            <div class="option__desc">
              <span class="option__title">
                {{ dt.option.BankName }} - {{ dt.option.AccountNumber }}
                {{ dt.option.AccountName }}
              </span>
            </div>
          </template>
        </multiselect>
      </div>
    </div>
    <div class="vx-row mb-2">
      <div class="vx-col w-full">
        <vs-input
          class="w-full"
          label="Total Paid"
          name="Total Paid"
          v-model="create.nominal"
          readonly
        />
      </div>
    </div>
    <div class="vx-row margin-btm" style="width: 100%; margin-left: 0%">
      <vs-button @click="handleSubmit()" icon-pack="feather" icon="icon-save">
        Submit
      </vs-button>
    </div>
  </div>
</template>
<script>
import Datepicker from "vuejs-datepicker";

export default {
  components: {
    Datepicker,
  },
  props: {
    selected: {
      type: Object,
    },
  },
  created() {},
  data() {
    return this.initialState();
  },
  methods: {
    initialState() {
      return {
        optionSupplierAccountName: [],
        create: {
          supplierAccount: {},
          nominal: 0,
        },
      };
    },
    handleSubmit() {
      console.log("handle");
      this.$vs.loading();
      // console.log(this.create.supplierAccount)
      this.$http
        .post("/api/v1/pi/advance-purchase-payment", {
          pi_id: this.selected.id,
          supplier_account: this.create.supplierAccount.ID,
          total_paid: this.create.nominal.toString().replace(/\d/gi,'')
        })
        .then((resp) => {
          console.log(resp);
          this.$nextTick(() => {
            // this.$emit("closeDetail");
            this.$emit("closeDetail", true);
            this.$vs.loading.close();
          });
        });
    },
    getOptionAccountBank() {
      this.$vs.loading();
      this.$http
        .get("/api/v1/pi/supplier-account/" + this.selected.supplier_id, {
          params: {
            order: "name",
            sort: "asc",
          },
        })
        .then((resp) => {
          console.log(resp);
          this.optionSupplierAccountName = resp.data.account;
          this.create.nominal = this.selected.total_invoice
            .toString()
            .replace(/\B(?=(\d{3})+(?!\d))/g, ".");
          this.$vs.loading.close();
        });
    },
  },
  mounted() {
    // if (this.piId) {
    // this.getData();
    this.getOptionAccountBank();
    // this.table.inv_code = this.invCode != 0 ? this.invCode : null;
    // }
  },
  computed: {},
  watch: {
    selected() {
      Object.assign(this.$data, this.initialState());
      // this.getData();
      this.getOptionAccountBank();
      // this.table.inv_code = this.invCode != 0 ? this.invCode : null;
    },
  },
};
</script>
<style scoped>
.pagination {
  display: flex;
  margin: 0.25rem 0.25rem 0;
}

.pagination button {
  flex-grow: 1;
}

.pagination button:hover {
  cursor: pointer;
}

.horizontal-divider {
  border-top: 1px solid #c1bbff;
  padding: 5px;
  margin-top: 15px;
}
</style>